import { Outlet, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Container, Grid } from "@mui/material";
// pubnub
import { AuthContext } from "../Context/AuthContext";
import HeaderLayout from "./HeaderLayout";

const AdminLayout = () => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);

  return (
    <>
      {token && (
        <div component="main">
          <HeaderLayout />
          <Container maxWidth="xl">
            <Outlet />
          </Container>
        </div>
      )}
    </>
  );
};

export default AdminLayout;
