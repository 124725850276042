// React import
import { Outlet, useRouteError } from "react-router-dom";
//React Icons
import { AiOutlineHome } from "react-icons/ai";
import { FaRegUserCircle } from "react-icons/fa";
import { AiOutlineSetting } from "react-icons/ai";
import { HiChip } from "react-icons/hi";
import { RiFileUserLine } from "react-icons/ri";
//Main Module
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";
import Unauthorized from "./component/Unauthorized";
//Auth Component
import SignIn from "./views/SignIn/Signin";
import Home from "./views/Home/Home";
// core component
const RootErrorBoundary = () => {
  let error = useRouteError();
  return (
    <div>
      <h1>Uh oh, something went terribly wrong 😩</h1>
      <pre>{error.message || JSON.stringify(error)}</pre>
      <button onClick={() => (window.location.href = "/")}>
        Click here to reload the app
      </button>
    </div>
  );
};

export const routes = [
  {
    element: <AuthLayout />,
    children: [
      { path: "/", element: <SignIn /> },
      { path: "*", element: <Unauthorized /> },
      {
        path: "",
        element: <Outlet />,
        errorElement: <RootErrorBoundary />,
        children: [],
      },
    ],
  },
  {
    element: <AdminLayout />,
    children: [
      {
        invisible: false,
        name: "Device Management",
        path: "/home",
        element: <Home />,
        icon: <HiChip />,
      },
    ],
  },
];
