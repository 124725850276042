import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Button,
  DialogActions,
  TextField,
  Typography,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Grid } from "@mui/material";
import { useForm, RegisterOptions } from "react-hook-form";
import { FiSave } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../component/axios/axiosInstance";

export default function Home() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    trigger,
  } = useForm();
  const navigate = useNavigate();

  const registerOptions = { required: true };
  const [homeResp, setHomeResp] = useState("");
  const [roomResp, setRoomResp] = useState("");
  const [selectRoomValues, setSelectRoomValues] = useState({
    home: null,
  });
  const [selectedRooms, setSelectedRooms] = useState([]);

  const homeId = selectRoomValues?.home?._id;
  const handleClearHome = () => {
    if (selectRoomValues?.home) {
      setSelectRoomValues((prevState) => ({
        ...prevState,
        home: null,
      }));
    }
  };

  const handleClearRoom = () => {
    setSelectedRooms([]);
  };

  const handleAutocompleteChange = (event, value, field) => {
    setSelectRoomValues((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setSelectedRooms([]);
  };

  const handleAutocompleteChangeRoom = (field, newValue) => {
    setSelectedRooms(newValue);
    setValue(field, newValue, { shouldValidate: true });
    trigger(field);
  };

  const RoomId = selectedRooms?.map((item) => item._id);

  const GethomebyuserId = async () => {
    try {
      const resp = await axiosInstance.get("api/home/gethomebyuserId");
      setHomeResp(resp?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const GetRoombyHome = async () => {
    let body = { homeId: homeId };
    try {
      const resp = await axiosInstance.post("/api/room/getRoombyHome", body);
      setRoomResp(resp?.data?.msg);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    GethomebyuserId();
  }, []);
  useEffect(() => {
    if (homeId) {
      GetRoombyHome();
    }
  }, [homeId]);

  const handleSubmitData = async () => {
    const accessToken = localStorage.getItem("userData");
    const userData = JSON.parse(accessToken);
    let body = {
      homeId: selectRoomValues.home?._id,
      roomId: RoomId,
      responseUrl: userData.responseUrl,
    };
    try {
      const resp = await axiosInstance.post(
        `/api/voiceAssistant/googleHome/selection`,
        body
      );
      window.location.href = resp.data.redirectUrl;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        <Grid container mt={12}>
          <Typography className="mt-20px" component="h1">
            "Welcome to the Home Automation System! To get started, please
            follow these steps:"
          </Typography>
          <Typography className="mt-20px">
            "Step 1: Now, select your home from the dropdown menu. This ensures
            that your room's settings are applied to the right location."
          </Typography>
          <Typography className="mt-20px">
            "Step 2: Select a room from the dropdown menu below. This will help
            us customize the automation settings for that room."
          </Typography>
          <Autocomplete
            id="home"
            className="mt-20px width100"
            options={homeResp || []}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Select Home"
                error={!selectRoomValues.home && !!errors.home}
                helperText={
                  !selectRoomValues.home
                    ? errors.home && "Home   is required"
                    : ""
                }
                {...register("home", registerOptions)}
              />
            )}
            clearIcon={<ClearIcon onClick={handleClearHome} />}
            value={selectRoomValues.home || null}
            onChange={(event, value) =>
              handleAutocompleteChange(event, value, "home")
            }
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.name, inputValue, {
                insideWords: true,
              });
              const parts = parse(option.name, matches);

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />

          <Autocomplete
            id="selectedRooms"
            multiple
            className="mt-20px width100"
            options={roomResp || []}
            getOptionLabel={(option) => option.roomName}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Select Room" />
            )}
            clearIcon={<ClearIcon onClick={handleClearRoom} />}
            value={selectedRooms}
            onChange={(_, newValue) =>
              handleAutocompleteChangeRoom("selectedRooms", newValue)
            }
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.roomName, inputValue, {
                insideWords: true,
              });
              const parts = parse(option.roomName, matches);
              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              );
            }}
          />
          <Typography align="center" className="width100 mt-20px">
            Here's the data for the selected room in the selected home
          </Typography>
          <Grid container justifyContent={"center"} mt={3}>
            {selectedRooms.length > 0 && (
              <Grid
                item
                xs={8}
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  padding: "20px 10px",
                }}
              >
                <Typography component={"h3"} align="center" fontSize={"18px"}>
                  Home Name - {selectRoomValues?.home?.name}
                </Typography>
                <Typography align="center" component={"h3"} fontSize={"18px"}>
                  Room Name
                </Typography>
                <Grid container sx={{ justifyContent: "center" }}>
                  <Typography>
                    {selectedRooms.map((data) => `${data.roomName}`)}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container justifyContent={"flex-end"} mt={2}>
            <Button
              startIcon={<FiSave />}
              type="submit"
              style={{
                color: "#fff",
                backgroundColor: "#32325d",
                border: `1px solid ${"#ddd"}`,
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "white",
                },
              }}
            >
              {" "}
              Submit{" "}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
